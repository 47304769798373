@import 'clip.scss';
$redColor: rgba(244, 67, 54, 1);
$blueColor: rgba(33, 150, 243, 1);
:root {
	--clr-neon: hsl(317 100% 54%);
}
.barba-once {
	opacity: 0;
}

.barba-once-active {
	transition: opacity 3s linear;
}

.barba-once-to {
	opacity: 1;
}
body {
	overscroll-behavior-x: none;
}
#app {
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	position: fixed;
	top: 0;
	left: 0;
	background-color: #131319;
}

canvas {
	-webkit-touch-callout: none;
	/* iOS Safari */
	-webkit-user-select: none;
	/* Safari */
	-khtml-user-select: none;
	/* Konqueror HTML */
	-moz-user-select: none;
	/* Firefox */
	-ms-user-select: none;
	/* Internet Explorer/Edge */
	user-select: none;
	/* Non-prefixed version, currently */
}

.slides {
	box-sizing: border-box;
	position: absolute;
	top: 50%;
	padding: 0 10vw;
	width: 100%;
	max-width: 100%;
	-webkit-touch-callout: none;
	/* iOS Safari */
	-webkit-user-select: none;
	/* Safari */
	-khtml-user-select: none;
	/* Konqueror HTML */
	-moz-user-select: none;
	/* Firefox */
	-ms-user-select: none;
	/* Internet Explorer/Edge */
	user-select: none;
	/* Non-prefixed version, currently */
}

.slide {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
	height: 33vh;
	color: #fff;
	transform: translateY(-50%);
	transition: transform 1300ms cubic-bezier(0.2, 1, 0.3, 1),
		opacity 500ms cubic-bezier(0.2, 1, 0.3, 1);
}

.slide-meta {
	margin: 0;
	font-size: 1.35rem;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.slide-meta,
.slide-more {
	opacity: 0;
	transition: opacity 900ms cubic-bezier(0.2, 1, 0.3, 1);
}

.show-meta .slide-meta,
.show-meta .slide-more {
	opacity: 1;
	transition: opacity 1500ms 300ms cubic-bezier(0.2, 1, 0.3, 1);
}

.slide-more {
	border-bottom: 1px solid;
}

.slide-more:hover {
	border-bottom: 1px solid transparent;
}

.slide-title {
	font-size: 10vw;
	margin: 0;
	color: #fafafa;
	text-indent: -0.5vw;
	font-family: Futura, Trebuchet MS, Arial, sans-serif;
	font-weight: 900;
	transition: color 500ms cubic-bezier(0.2, 1, 0.3, 1);
}

.prev,
.next {
	opacity: 0;
}

.prev {
	transform: translateY(-85%);
}

.next {
	transform: translateY(-15%);
}

.prev .slide-title,
.next .slide-title {
	-webkit-text-stroke: 1.5px #fafafa;
	text-stroke: 1.5px #fafafa;
	-webkit-text-fill-color: transparent;
	text-fill-color: transparent;
	color: transparent;
}

.scrolling > .slide {
	transform: translateY(-50%);
	opacity: 1;
}

/* .content__project-container {
	width: 100%;
	display: flex;
	justify-content: space-between;
} */

/* .content__project-description {
	width: 50%;
	margin: 5%;
	margin-right: 20%;
} */

/* .content__title {
	width: 50%;
	margin: 5%;
	margin-left: 20%;
} */

/* .content__project-overview-container {
	width: 100%;
	display: flex;
	justify-content: space-between;
} */

/* .content__overview {
	width: 50%;
	margin: 5%;
	margin-left: 20%;

} */

/* .content__project-overview {
	width: 50%;
	margin: 5%;
	margin-right: 20%;
} */

/* .hero-image {
	position: relative;
	top: 10%;
	display: flex;
	justify-content: center;
	/* margin-top: 30px; */
/* } */
/* .hero-image img {
	width: 90%;
	top: 10%;
	z-index: 0;
	object-fit: cover;
}
*/

/* .content__project-overview-container { */
/* position: relative; */
/* display: flex; */
/* width: 100%; */
/* } */

/* .content__image-gallery1 {
	display: flex;
	justify-content: center;
	width: 100%;
}
.content__image-container1 {
	width: 38%;
	margin: 3%;
}
.content__image-container2 {
	width: 38%;
	margin: 3%;
}
.content__image-container1 img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.content__image-container2 img {
	width: 100%;
	height: 100%;
	object-fit: cover;
} */
/* .hero-text {
	text-align: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
} */

.inner-content {
	display: flex;
	justify-content: center;
	align-items: center;
	/* height: 90vh; */
}

.hero-image {
	margin-top: 5em;
	display: flex;
	justify-content: center;
	width: 100vw;
	height: 75vh;
}

.hero-image img {
	/* width: 90%; */
	width: 100%;
	height: 100%;
	padding-right: 5em;
	padding-left: 5em;
	margin-left: auto;
	margin-right: auto;
	object-fit: cover;
}

.content__description__container {
	display: flex;
	padding: 5em;
}

.about__content__description__container {
	display: flex;
	padding: 5em;
	padding-top: 13em;
	justify-content: center;
	letter-spacing: 3px;
}

/* .content__description__container h1 {
	width: 50%;
} */

.content__title {
	width: 50%;
}

.about__content__title {
	width: 100%;
}

.content__description {
	width: 75%;
}
.content__enter {
	/* position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%); */
}
/* .content__description__container p {
	width: 50%;
} */

.content__gallery {
	display: flex;
	width: 100vw;
	justify-content: center;
}
.content__gallery img {
	width: 50%;
	object-fit: cover;
}

.image1 {
	padding-left: 5em;
}

.image2 {
	padding-right: 5em;
}

.content__gallery__2 {
	display: flex;
	width: 100vw;
	justify-content: center;
}

.content__gallery__2 img {
	width: 100%;
	padding-top: 1em;
	padding-left: 5em;
	padding-right: 5em;
	padding-bottom: 1em;
	object-fit: cover;
}

.content__gallery__3 {
	position: relative;
	display: flex;
	width: 100vw;
	justify-content: center;
}

.content__gallery__3 video {
	max-width: 100%;
	height: auto;
	display: block;
	padding-right: 5em;
	padding-bottom: 5em;
}

#dieLosungVideo {
	padding-bottom: 1em;
}

.line {
	overflow: hidden;
	display: inline-block;
}
/* .content__gallery__3 video {
	position: absolute;
	width: 100%;
	padding-top: 1em;
	padding-left: 5em;
	padding-right: 5em;
	padding-bottom: 1em;
	object-fit: cover;
} */
/* .neon-button {
	font-size: 4rem;
	cursor: pointer;
	text-decoration: none;
	border: var(--clr-neon) 0.125em solid;
	border-radius: 0.25em;
	padding: 0.25em 1em;
	color: var(--clr-neon);
	text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.5), 0 0 0.45em currentColor;
	box-shadow: 0 0 0.5em 0 var(--clr-neon);
} */
.neon-button {
	position: relative;
	display: inline-block;
	padding: 0.5em 1em;
	color: #2196f3;
	text-transform: uppercase;
	letter-spacing: 4px;
	text-decoration: none;
	font-size: 1em;
	overflow: hidden;
	transition: 0.2s;
}

.neon-button:hover {
	color: #255784;
	background: #2196f3;
	box-shadow: 0 0 10px #2196f3, 0 0 40px #2196f3, 0 0 80px #2196f3;
	transition-delay: 1s;
}

.neon-button span {
	position: absolute;
	display: block;
}

.neon-button span:nth-child(1) {
	top: 0;
	left: -100%;
	width: 100%;
	height: 2px;
	background: linear-gradient(90deg, transparent, #2196f3);
}

.neon-button:hover span:nth-child(1) {
	left: 100%;
	transition: 1s;
}
.neon-button span:nth-child(3) {
	bottom: 0;
	right: -100%;
	width: 100%;
	height: 2px;
	background: linear-gradient(270deg, transparent, #2196f3);
}

.neon-button:hover span:nth-child(3) {
	right: 100%;
	transition: 1s;
	transition-delay: 0.5s;
}
.neon-button span:nth-child(2) {
	top: -100%;
	right: 0%;
	width: 2px;
	height: 100%;
	background: linear-gradient(180deg, transparent, #2196f3);
}

.neon-button:hover span:nth-child(2) {
	right: 100%;
	transition: 1s;
	transition-delay: 0.25s;
}
.neon-button span:nth-child(4) {
	bottom: -100%;
	left: 0%;
	width: 2px;
	height: 100%;
	background: linear-gradient(360deg, transparent, #2196f3);
}

.neon-button:hover span:nth-child(4) {
	bottom: 100%;
	transition: 1s;
	transition-delay: 0.75s;
}

.loader {
	position: fixed;
	top: 0;
	left: 0;
	pointer-events: none;
	z-index: 10;
	width: 100%;
	height: 100vh;
}
.page-transition {
	position: absolute;
	top: 0;
	background-color: #000000;
	width: 100%;
	height: 0%;
}
.container {
	position: absolute;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	width: 100%;
}
.link {
	$self: &;
	display: inline-flex;
	align-items: center;
	font-size: 1rem;
	padding: 0 0 0 1rem;
	text-decoration: none;
	grid-column-start: 2;
	grid-column-end: five;

	// transform: rotate(-90deg) translate3d(-300px, 0, 0);

	&__arrow {
		display: inline-flex;

		span {
			position: relative;
			width: 14px;
			height: 2px;
			border-radius: 2px;
			overflow: hidden;
			background: #bebdbe;
			z-index: 2;

			&:nth-child(1) {
				transform-origin: left bottom;
				transform: rotate(45deg) translate3d(8px, -10px, 0);
			}

			&:nth-child(2) {
				transform-origin: left bottom;
				transform: rotate(-45deg);
			}

			&:after {
				content: '';
				display: block;
				position: absolute;
				left: 0;
				width: 0;
				height: 2px;
				background: #c3002f;
			}
		}
	}

	&__line {
		position: relative;
		margin-left: -14px;
		margin-right: 30px;
		width: 75px;
		height: 2px;
		background: #bebdbe;
		overflow: hidden;
		z-index: 1;

		&:after {
			content: '';
			display: block;
			position: absolute;
			left: 80px;
			width: 70px;
			height: 2px;
			background: #c3002f;
		}
	}

	&__text {
		// color: #4a4a4a;
		// font-family: 'Oswald', sans-serif;
		font-size: 1rem;
		text-transform: uppercase;
	}

	&:hover {
		#{$self} {
			&__line {
				&:after {
					animation: animation-line 1.5s forwards;
				}
			}

			&__arrow {
				span {
					&:after {
						animation: animation-arrow 1.5s forwards;
						animation-delay: 1s;
					}
				}
			}
		}
	}
}

@keyframes animation-line {
	0% {
		left: 80px;
	}

	100% {
		left: 0;
	}
}

@keyframes animation-arrow {
	0% {
		width: 0;
	}

	100% {
		width: 100%;
	}
}

.webgl {
	// overflow: hidden;
	position: absolute;
	width: 100vw;
	height: 100vw;
	top: 0;
	z-index: -1;
	// opacity: 1;
}

.about__inner-content {
	position: relative;
	left: 0;
	display: flex;
	width: 50vw;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
// .parallax {
// 	opacity: 0;
// 	transition: opacity 3s;
// }
// .scroll-class {
// 	opacity: 1;
// }

#helloh1 {
	opacity: 0;
}

#helloP {
	opacity: 0;
}

#youCanFindMe {
	opacity: 0;
}
.flex-center {
	width: 100%;
	opacity: 0;
	font-size: 2em;
	background: #0e0e0f;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: left;
	-webkit-justify-content: left;
	-ms-flex-pack: left;
	justify-content: left;
	-webkit-box-align: left;
	-webkit-align-items: left;
	-ms-flex-align: left;
	align-items: left;
}

.icon-3d {
	padding: 10px;
	-webkit-animation: icon3d 200ms 10;
	animation: icon3d 200ms 10;
	color: #fff;
	&:hover {
		-webkit-animation: icon3d 200ms infinite;
		animation: icon3d 200ms infinite;
	}
}

@keyframes icon3d {
	0% {
		text-shadow: 5px 4px $redColor, -5px -6px $blueColor;
	}
	25% {
		text-shadow: -5px -6px $redColor, 5px 4px $blueColor;
	}
	50% {
		text-shadow: 5px -4px $redColor, -8px 4px $blueColor;
	}
	75% {
		text-shadow: -8px -4px $redColor, -5px -4px $blueColor;
	}
	100% {
		text-shadow: -5px 0 $redColor, 5px -4px $blueColor;
	}
}

#youCanFindMe {
	margin-bottom: 0;
}

.fa-4x {
	font-size: 1em;
}
