/* Clip-path transition */
.clip-leave {
	opacity: 1;
}

.clip-leave-active {
	transition: opacity 0.75s linear;
}

.clip-leave-to {
	opacity: 0;
}

.clip-enter {
	clip-path: circle(0%);
}

.clip-enter-active {
	transition: all 0.75s linear;
	position: absolute;
	top: 0;
	z-index: 2;
}

.clip-enter-to {
	clip-path: circle(75%);
}

//
.fade-leave {
	opacity: 1;
}

.fade-leave-active {
	transition: opacity 0.75s linear;
}

.fade-leave-to {
	opacity: 0;
}

.fade-enter {
	clip-path: circle(0%);
}

.fade-enter-active {
	transition: all 0.75s linear;
	position: absolute;
	top: 0;
	z-index: 2;
}

.fade-enter-to {
	clip-path: circle(75%);
}

// /* Clip-path transition */
// .clip-enter {
// 	clip-path: circle(0%);
// }

// .clip-enter-active {
// 	transition: all 0.75s var(--easing);
// 	position: absolute;
// 	top: 0;
// 	z-index: 2;
// }

// .clip-enter-to {
// 	clip-path: circle(75%);
// }

// .clip-leave {
// 	opacity: 1;
// }

// .clip-leave-active {
// 	transition: all 0.75s var(--easing);
// }

// .clip-leave-to {
// 	opacity: 0;
// }
